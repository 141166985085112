import { mapMutations, mapState, mapGetters } from 'vuex';
import { getItem } from '../../../../api/api-methods';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

import KnTable from '@/modules/shared/components/KnTable.vue';
export default {
  name: 'GroupIndex',
  components: { KnTable },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      groups: [],
      headers: [
        { text: '', value: 'foto' },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        {
          text: 'No. de alumnos',
          value: 'alumnos',
          class: 'purple--text',
        },
        {
          text: 'Ciclo escolar',
          value: 'ciclo',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      // search: null,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    ...mapGetters(['isBasicEmployee']),
    getEmployeeId() {
      return this.isBasicEmployee
        ? this.userData.perfiles[3].empleado[0].id
        : null;
    },
    buttomActionText() {
      return this.showInactive ? 'Ver Grupos Activos' : 'Ver Grupos Inactivos';
    },
    tableTitle() {
      return this.showInactive ? 'Grupos Inactivos' : 'Grupos';
    },
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedGroups(1);
      },
    },
    showInactive: {
      async handler() {
        await this.getPaginatedGroups(1);
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    // console.log('perfiles', this.userData.perfiles[3].empleado[0].id);
    // await this.fetchGroups(!this.showInactive);
    // this.setPaginationLimit(2);
    await this.getPaginatedGroups(1);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchGroups({
      institutionId = null,
      employeeId = null,
      groupName = null,
      systemStatus = null,
      limit = null,
      offset = 0,
    }) {
      try {
        this.loading = true;
        this.group = [];
        this.items = [];
        const institutionFilter = institutionId
          ? `institucion_educativa=${institutionId}`
          : '';
        const teacherFilter = employeeId ? `&profesores=${employeeId}` : '';
        const systemStatusFilter =
          systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
        const searchFilter =
          groupName !== null ? `&nombre_grupo=${groupName}` : '';
        const limitFilter = limit !== null ? `&limit=${limit}` : '';
        const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
        this.groups = await getItem(
          '/app-personas/filters/grupo?'.concat(
            institutionFilter,
            teacherFilter,
            systemStatusFilter,
            searchFilter,
            limitFilter,
            offsetFilter
          )
        );
        this.setPaginationCount(this.groups.count);
        for (const group of this.groups.results) {
          const item = {
            foto: group.imagen_grupo ? group.imagen_grupo.imagen : null,
            nombre: group.nombre_grupo,
            alumnos: group.alumnos.length,
            ciclo: group.ciclo_escolar
              ? group.ciclo_escolar.dato
              : 'Estimulación temprana',
          };
          this.items.push(item);
        }
      } catch (error) {
        console.error('Error al obtener grupos. ', error);
      } finally {
        this.loading = false;
        // setTimeout(() => (this.loading = false), 500);
      }
    },
    async getPaginatedGroups(page = 1) {
      // console.log('this.getPaginatedGroups page', page);
      this.setPaginationPage(page);
      if (!this.loading) {
        await this.fetchGroups({
          institutionId: this.institutionId,
          employeeId: this.getEmployeeId,
          groupName: this.search,
          systemStatus: !this.showInactive,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
      }
    },
  },
};
